exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lets-collaborate-tsx": () => import("./../../../src/pages/lets-collaborate.tsx" /* webpackChunkName: "component---src-pages-lets-collaborate-tsx" */),
  "component---src-pages-the-blog-author-author-author-id-tsx": () => import("./../../../src/pages/the-blog/author/{Author.author_id}.tsx" /* webpackChunkName: "component---src-pages-the-blog-author-author-author-id-tsx" */),
  "component---src-pages-the-blog-index-tsx": () => import("./../../../src/pages/the-blog/index.tsx" /* webpackChunkName: "component---src-pages-the-blog-index-tsx" */),
  "component---src-pages-the-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-custom-vs-pre-made-solutions-why-an-outsourcing-web-development-agency-is-the-way-to-go-index-mdx": () => import("./../../../src/pages/the-blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/danilozekovic/PROJECTS/arrideo.io/blog/custom-vs-pre-made-solutions-why-an-outsourcing-web-development-agency-is-the-way-to-go/index.mdx" /* webpackChunkName: "component---src-pages-the-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-custom-vs-pre-made-solutions-why-an-outsourcing-web-development-agency-is-the-way-to-go-index-mdx" */),
  "component---src-pages-the-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-ui-and-ux-what-exactly-is-the-difference-index-mdx": () => import("./../../../src/pages/the-blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/Users/danilozekovic/PROJECTS/arrideo.io/blog/ui-and-ux-what-exactly-is-the-difference/index.mdx" /* webpackChunkName: "component---src-pages-the-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-ui-and-ux-what-exactly-is-the-difference-index-mdx" */),
  "component---src-pages-the-services-tsx": () => import("./../../../src/pages/the-services.tsx" /* webpackChunkName: "component---src-pages-the-services-tsx" */)
}

